import styled from '@emotion/styled';
// import rhythm from '../../utils/rhythm';
import colors from '../../styles/modules/colors.module.scss';
import rhythm from '../../utils/rhythm';
// import utilities from '../../styles/modules/utilities.module.scss';

const Styles = styled.div({
  padding: `${rhythm(1 / 2)} ${rhythm(1)}`,
  backgroundColor: colors.purple,
  textAlign: 'center',
  lineHeight: rhythm(3 / 4),

  a: {
    color: colors.white,
  },
});

export default Styles;
