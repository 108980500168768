import React from 'react';
import Styles from './covidBanner.styles';
import { Link } from 'gatsby';

export default function CovidBanner() {
  return (
    <Styles>
      <Link to={`/covid-19-response/`}>COVID-19 Response: Learn How We're Protecting Our Clients</Link>
    </Styles>
  );
}
