import styled from '@emotion/styled';
// import responsive from '../../utils/responsive';
import rhythm from '../../utils/rhythm';
// import colors from '../../styles/modules/colors.module.scss';

const Styles = styled.footer({
  padding: `${rhythm(2)} ${rhythm(1 / 4)} ${rhythm(4)}`,
});

export default Styles;
