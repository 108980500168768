import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Styles from './mobileMenu.styles';

// Based on: https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const MobileMenu = ({ open }) => {
  // const data = useStaticQuery(graphql`
  //   query MobileNavQuery {
  //     wp {
  //       globalContent {
  //         globalContact {
  //           phone
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <Styles open={open}>
      <ul className="list-unstyled text-center">
        <li>
          <Link to={`/what-we-treat/`} activeClassName="active">
            What We Treat
          </Link>
        </li>
        <li>
          <Link to={`/how-we-treat/`} activeClassName="active">
            How We Treat
          </Link>
        </li>
        <li>
          <Link to={`/who-we-are/`} activeClassName="active">
            Who We Are
          </Link>
        </li>
        <li>
          <Link to={`/insurance/`} activeClassName="active">
            Insurance
          </Link>
        </li>
        <li>
          <Link to={`/our-location/`} activeClassName="active">
            Our Location
          </Link>
        </li>
        <li>
          <Link to={`/contact-us/`} activeClassName="active">
            Contact Us
          </Link>
        </li>
      </ul>
      <a
        href={`tel:(866) 209-6600`}
        className={`btn btn-success btn-sm btn-call`}
      >
        Call (866) 209-6600
      </a>
    </Styles>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MobileMenu;
