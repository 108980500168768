import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

import Styles from './footer.styles';
import { Container, Row, Col } from 'react-bootstrap';
import Img from 'gatsby-image';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        wp {
          globalContent {
            globalCertifications {
              certificationsHeadline
              certificationsList {
                title
                badge {
                  localFile {
                    ...imageFluidSmall
                  }
                  altText
                }
              }
            }
            globalContact {
              phone
              address
            }
          }
        }
      }
    `}
    render={(data) => (
      <Styles>
        <Container>
          <Row className={`text-center justify-content-center`}>
            <Col xs={10} md={8}>
              <h4 className={`d-block text-center mb-3`}>Contact Us</h4>
              <Row>
                <Col xs={12} md={6}>
                  <address>
                    8946 Madison Avenue <br/>
                    Fair Oaks, CA 95628-4010
                  </address>
                </Col>
                <Col xs={12} md={6}>
                  <address>
                    8950 Cal Center Drive <br/>
                    Suite 165B <br/>
                    Sacramento, CA 95826
                  </address>
                </Col>
              </Row>
              <a
                href={`tel:(866) 209-6600`}
                className={`btn btn-success btn-call mt-3`}
              >
                Call (866) 209-6600
              </a>
            </Col>
          </Row>

          <hr className={`my-4`} />

          <Row>
            <Col>
              <ul className={`nav text-center justify-content-center`}>
                <li className={`nav-item`}>
                  <Link to={`/what-we-treat/`} className={`nav-link`}>
                    What We Treat
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/how-we-treat/`} className={`nav-link`}>
                    How We Treat
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/who-we-are/`} className={`nav-link`}>
                    Who We Are
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/insurance/`} className={`nav-link`}>
                    Insurance
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/our-location/`} className={`nav-link`}>
                    Our Location
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/contact-us/`} className={`nav-link`}>
                    Contact Us
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link to={`/privacy-policy/`} className={`nav-link`}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>

          <hr className={`my-4`} />

          <h4 className={`d-block text-center mb-4`}>Certified By</h4>
          <Row className={`text-center justify-content-center`}>
            {data.wp.globalContent.globalCertifications.certificationsList.map(
              (certification, index) => (
                <Col xs={4} md={3} key={index}>
                  <Img
                    fluid={certification.badge.localFile.childImageSharp.fluid}
                    alt={certification.badge.altText}
                    className={`d-inline-block w-50 mx-auto`}
                  />
                  <h5 className={`mt-3`}>
                    <small>{certification.title}</small>
                  </h5>
                </Col>
              )
            )}
          </Row>

          <hr className={`my-4`} />
          <p className={`text-muted text-center`}>
            <small>
              A{' '}
              <a
                href={`https://www.sprouthealthgroup.com/`}
                target={`_blank`}
                className={`text-success`}
                style={{ fontWeight: 500 }}
              >
                Sprout Health Group
              </a>{' '}
              Location
            </small>
          </p>
        </Container>
      </Styles>
    )}
  />
);

export default Footer;
