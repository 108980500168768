import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Navbar } from 'react-bootstrap';

import { ReactComponent as Logo } from '../../images/clean-and-sober-logo.svg';
import rhythm from '../../utils/rhythm';

export default function TopNav() {
  // const data = useStaticQuery(graphql`
  //   query TopNavQuery {
  //     wp {
  //       globalContent {
  //         globalContact {
  //           phone
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <Navbar bg="light" expand="xl" sticky="top">
      <Link to={`/`} className={`navbar-brand`}>
        <Logo style={{ height: `${rhythm(3 / 2)}`, width: 'auto' }} />
      </Link>

      <Navbar.Collapse id="basic-navbar-nav">
        <ul className="mr-auto navbar-nav">
          <li>
            <Link to={`/what-we-treat/`} activeClassName="active">
              What We Treat
            </Link>
          </li>
          <li>
            <Link to={`/how-we-treat/`} activeClassName="active">
              How We Treat
            </Link>
          </li>
          <li>
            <Link to={`/who-we-are/`} activeClassName="active">
              Who We Are
            </Link>
          </li>
          <li>
            <Link to={`/insurance/`} activeClassName="active">
              Insurance
            </Link>
          </li>
          <li>
            <Link to={`/our-location/`} activeClassName="active">
              Our Location
            </Link>
          </li>
          <li>
            <Link to={`/contact-us/`} activeClassName="active">
              Contact Us
            </Link>
          </li>
        </ul>

        <a
          href={`tel:(866) 209-6600`}
          className={`btn btn-success btn-sm btn-call`}
        >
          Call Now
        </a>
      </Navbar.Collapse>
    </Navbar>
  );
}

TopNav.defaultProps = {
  siteTitle: ``,
};

TopNav.propTypes = {
  siteTitle: PropTypes.string,
};
